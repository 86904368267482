/* This example requires Tailwind CSS v2.0+ */

export default function Banner() {
  return (
    <div className="relative bg-black overflow-hidden">
      <div className="relative pt-6 pb-16 sm:pb-24">
        <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
          <div className="text-center">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-100 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">Turn <span className='text-red-600'>Python</span></span>{' '}
              <span className="block xl:inline">into your <span className='text-red-600'>Ally</span></span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-400 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Book a <span className="underline decoration-red-500 decoration-4 underline-offset-2">Free Consulting Session</span> and discover how to become a <span className="underline decoration-red-500 decoration-4 underline-offset-2">Python Resolver</span>
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <a
                  href="https://wa.me/34618656122"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 md:py-4 md:text-lg md:px-10 hover:text-gray-200"
                >
                  Send WhatsApp
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
