/* This example requires Tailwind CSS v2.0+ */

import oxford from "../assets/img/logos/oxford.svg";
import ie from "../assets/img/logos/ie.svg";
import bbva from "../assets/img/logos/bbva.svg";
import santander from "../assets/img/logos/santander.svg";
import cunef from "../assets/img/logos/cunef.svg";
import bancoesp from "../assets/img/logos/banco_espana.svg";

export default function Companies() {
    return (
      <div className="bg-black">
        <div className="max-w-7xl mx-auto pb-16 px-4 sm:py-20 sm:px-6 lg:px-8">
            <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
              Where our clients work & study
            </p>
          <div className="flow-root mt-8 lg:mt-10">
            <div className="-mt-4 -ml-8 flex flex-wrap justify-center lg:-ml-4">
              <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4 justify-center">
                <img className="w-44 text-white" src={oxford} alt="Tuple" />
              </div>
              <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4 justify-center">
                <img
                  className="h-12 w-44"
                  src={ie}
                  alt="StaticKit"
                />
              </div>
              <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4 justify-center">
                <img
                  className="h-12 w-44"
                  src={santander}
                  alt="Transistor"
                />
              </div>
              <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4 justify-center">
                <img
                  className="h-12 w-44"
                  src={bancoesp}
                  alt="Workcation"
                />
              </div>
              <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4 justify-center">
                <img
                  className="h-12 w-44"
                  src={cunef}
                  alt="Workcation"
                />
              </div>
              <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4 justify-center">
                <img
                  className="h-12 w-44"
                  src={bbva}
                  alt="Workcation"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  