/* This example requires Tailwind CSS v2.0+ */
import {
  ChatAlt2Icon,
  CursorClickIcon,
  PuzzleIcon,
  UserIcon} from '@heroicons/react/outline'
  
const features = [
  { name: 'Interactive Learning', icon: ChatAlt2Icon, text: 'Dialog between Teacher and Student to solve the doubts in real-time.' },
  { name: 'Personalized Program', icon: UserIcon, text: "We'll adapt to your needs and specific wishes to reach your goals."},
  { name: 'Problem-solving Driven', icon: CursorClickIcon, text: "Identify and understand Python errors to learn how to filter solutions in Google and adapt them to your problems."},
  { name: 'Unleash your Creativity', icon: PuzzleIcon, text: "You won't become a machine that copy-pastes without sense, but a creative professional who solves any problem."},
]
  
export default function Features() {
  return (
    <div className="relative bg-black py-16 sm:pt-24 lg:pt-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <h2 className="text-base font-semibold tracking-wider text-red-500 uppercase">Don't learn concepts. Learn a discpline.</h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-200 tracking-tight sm:text-4xl">
            Why learn Python with us
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">A methodology designed to unleash your creativity and become an {' '}
           <span className="underline decoration-red-500 decoration-4 underline-offset-2" >independent & self-taught</span> professional
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-gray-900 rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-red-500 rounded-md shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-200 tracking-tight">{feature.name}</h3>
                    <p className="mt-5 text-base text-gray-500">
                      {feature.text}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
  