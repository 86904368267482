import React from "react";
import "./App.css";
import Banner from "./Asesoria/Banner";
import Beneficios from "./Asesoria/Beneficios";
import Companies from "./Asesoria/Companies";
import Features from "./Asesoria/Features";
import Stats from "./Asesoria/Stats";
// import Testimonials from "./Asesoria/Testimonials";
// get our fontawesome imports

function App() {
  return (
    <React.Fragment>
      <Banner/>
      <Beneficios/>
      <Features/>
      {/* <Testimonials/> */}
      <Companies/>
      <Stats/>
    </React.Fragment>
  );
}

export default App;
