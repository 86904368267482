/* This example requires Tailwind CSS v2.0+ */
import { AcademicCapIcon, CalendarIcon, ClockIcon, LightBulbIcon } from '@heroicons/react/outline'

const features = [
  { name: 'Duration & Delivery Times', description: "We'll estimate the time we'd last to solve your problem..", icon: ClockIcon},
  { name: 'Level Evaluation', description: "We'll calibrate your knowledge to understand the concepts you need (or not) to learn.", icon: AcademicCapIcon},
  { name: 'Planning', description: "We'll schedule the sessions you need to solve your problem.", icon: CalendarIcon},
  { name: 'Advices', description: "We'll give you references for materials and content you can use to learn faster.", icon: LightBulbIcon},
]

export default function Beneficios() {
  return (
    <div className="bg-gray-900">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div className='text-right'>
          <h2 className="text-base font-semibold text-red-400 uppercase tracking-wide">All you need</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-200">What you receive with our Free Consulting Session</p>
          <p className="mt-4 text-lg text-gray-400">
            We'll teach you the secret to power your professional brand and increase your chances on LinkedIn
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <feature.icon className="absolute h-6 w-6 text-red-500" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-200">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-400">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
